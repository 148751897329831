export enum GENDER {
	MALE = "MALE",
	FEMALE = "FEMALE",
	OTHER = "OTHER",
}

export enum ROLE {
	SUPER_ADMIN = "SUPER_ADMIN",
	ADMIN = "ADMIN",
	STAFF = "STAFF",
}

export enum CUSTOM_FIELD_TYPE {
	TEXT_SINGLE = "TEXT_SINGLE",
	TEXT_MULTI = "TEXT_MULTI",
	SELECT_SINGLE = "SELECT_SINGLE",
	SELECT_MULTI = "SELECT_MULTI",
	DATETIME = "DATETIME",
}

export enum CUSTOM_FIELD_MODEL {
	COMPANY = "COMPANY",
	USER = "USER",
	AGENT_INFO = "AGENT_INFO",
	AGENT_ADDRESS = "AGENT_ADDRESS",
	AGENT_CONTACT = "AGENT_CONTACT",
	CLIENT_INFO = "CLIENT_INFO",
	CLIENT_ADDRESS = "CLIENT_ADDRESS",
	CLIENT_CONTACT = "CLIENT_CONTACT",
	FILE = "FILE",
}

export enum DISCOUNT_TYPE {
	AMOUNT = "AMOUNT",
	PERCENTAGE = "PERCENTAGE",
}

export enum OFFER_TYPE {
	PRODUCTS = "PRODUCTS",
	CATEGORIES = "CATEGORIES",
	PRODUCT_TYPES = "PRODUCT_TYPES",
	RECEIPTS = "RECEIPTS",
}
