import { OFFER_TYPE } from "~served/configs";

import { bigMath } from "../helpers";

import { getDiscountAmount } from "./discount";
import {
	FileProductCalculatedValues,
	FileProductInputLike,
	FileProductOptionCalculatedValues,
	FileProductOptionInputLike,
	OfferLike,
} from "./types";

export const getAppliedOfferByProducts = <
	TFileProduct extends
		FileProductInputLike<FileProductCalculatedValues> = FileProductInputLike<FileProductCalculatedValues>,
	TFileProductOption extends
		FileProductOptionInputLike<FileProductOptionCalculatedValues> = FileProductOptionInputLike<FileProductOptionCalculatedValues>,
>(
	products: FileProductInputLike<TFileProduct, TFileProductOption>[],
	offer: OfferLike,
) => {
	const eligibleProducts: Record<string, number> = {};

	if (!offer.configs.length)
		return { offer: null, eligibleProducts, offerAmount: 0 };

	if (isReceiptOffer(offer))
		return { offer: null, eligibleProducts, offerAmount: 0 };

	products.forEach((fileProduct) => {
		const productTotalPrice = fileProduct.subtotal;

		offer.configs.forEach((config) => {
			switch (config.type) {
				case OFFER_TYPE.PRODUCTS:
					if (
						config.products.map(String).includes(fileProduct.product.toString())
					) {
						const { discountAmount } = getDiscountAmount(productTotalPrice, {
							type: config.discountType,
							value: config.discountValue,
						});

						if (!eligibleProducts[fileProduct._id.toString()]) {
							eligibleProducts[fileProduct._id.toString()] =
								discountAmount > productTotalPrice
									? productTotalPrice
									: discountAmount;
						} else if (eligibleProducts[fileProduct._id.toString()]) {
							if (
								(offer.isOfferingHigherValue &&
									discountAmount >
										eligibleProducts[fileProduct._id.toString()]) ||
								(!offer.isOfferingHigherValue &&
									discountAmount < eligibleProducts[fileProduct._id.toString()])
							) {
								eligibleProducts[fileProduct._id.toString()] =
									discountAmount > productTotalPrice
										? productTotalPrice
										: discountAmount;
							}
						}
					}
					break;
				case OFFER_TYPE.CATEGORIES:
					if (
						config.categories
							.map(String)
							.includes(fileProduct.category.toString())
					) {
						const { discountAmount } = getDiscountAmount(productTotalPrice, {
							type: config.discountType,
							value: config.discountValue,
						});

						if (!eligibleProducts[fileProduct._id.toString()]) {
							eligibleProducts[fileProduct._id.toString()] =
								discountAmount > productTotalPrice
									? productTotalPrice
									: discountAmount;
						} else if (eligibleProducts[fileProduct._id.toString()]) {
							if (
								(offer.isOfferingHigherValue &&
									discountAmount >
										eligibleProducts[fileProduct._id.toString()]) ||
								(!offer.isOfferingHigherValue &&
									discountAmount < eligibleProducts[fileProduct._id.toString()])
							) {
								eligibleProducts[fileProduct._id.toString()] =
									discountAmount > productTotalPrice
										? productTotalPrice
										: discountAmount;
							}
						}
					}
					break;
				case OFFER_TYPE.PRODUCT_TYPES:
					if (config.types.map(String).includes(fileProduct.type.toString())) {
						const { discountAmount } = getDiscountAmount(fileProduct.subtotal, {
							type: config.discountType,
							value: config.discountValue,
						});

						if (!eligibleProducts[fileProduct._id.toString()]) {
							eligibleProducts[fileProduct._id.toString()] =
								discountAmount > productTotalPrice
									? productTotalPrice
									: discountAmount;
						} else if (eligibleProducts[fileProduct._id.toString()]) {
							if (
								(offer.isOfferingHigherValue &&
									discountAmount >
										eligibleProducts[fileProduct._id.toString()]) ||
								(!offer.isOfferingHigherValue &&
									discountAmount < eligibleProducts[fileProduct._id.toString()])
							) {
								eligibleProducts[fileProduct._id.toString()] =
									discountAmount > productTotalPrice
										? productTotalPrice
										: discountAmount;
							}
						}
					}
					break;
			}
		});
	});

	return {
		offer,
		eligibleProducts,
		offerAmount: Object.values(eligibleProducts).reduce(
			(pre, cur) => bigMath.add(pre, cur),
			0,
		),
	};
};

export const getAppliedOfferByReceipt = (amount: number, offer: OfferLike) => {
	let offerAmount = 0;

	if (!offer.configs.length || !amount)
		return { offer: offer ?? null, offerAmount };

	if (!isReceiptOffer(offer)) return { offer: null, offerAmount };

	offer.configs.forEach((config) => {
		const { discountAmount } = getDiscountAmount(amount, {
			type: config.discountType,
			value: config.discountValue,
		});
		if (
			(offer.isOfferingHigherValue && discountAmount > offerAmount) ||
			(!offer.isOfferingHigherValue && discountAmount < offerAmount)
		) {
			offerAmount = discountAmount;
		}
	});

	return {
		offer,
		offerAmount,
	};
};

export const isReceiptOffer = (offer: OfferLike) => {
	return offer.configs.some((config) => config.type === OFFER_TYPE.RECEIPTS);
};
