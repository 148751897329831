import Big, { BigSource } from "big.js";

import { DISCOUNT_TYPE } from "~served/configs/enums";
import { Offer } from "~served/types/db";

import { bigMath } from "../helpers";

import { getDiscountAmount } from "./discount";
import {
	getAppliedOfferByProducts,
	getAppliedOfferByReceipt,
	isReceiptOffer,
} from "./offer";
import {
	DiscountInput,
	FileInputLike,
	FileProductCalculatedValues,
	FileProductInputLike,
	FileProductOptionCalculatedValues,
	FileProductOptionInputLike,
	ObjectLike,
	OfferLike,
	SubtotalAddonsLike,
	VenueLike,
} from "./types";

const BASE_SUBTOTAL_ADDONS: SubtotalAddonsLike = {
	offer: { amount: 0 },
	discount: {
		isDivided: false,
		type: DISCOUNT_TYPE.PERCENTAGE,
		value: 0,
		amount: 0,
	},
	vat: { isIncluded: false, percentage: 0, amount: 0 },
	adjustment: { amount: 0 },
};

const applyAdjustmentToProduct = <
	TFileProduct extends FileProductInputLike = FileProductInputLike,
	TFileProductOption extends
		FileProductOptionInputLike = FileProductOptionInputLike,
>({
	product,
	adjustmentAmount,
}: {
	product: FileProductInputLike<TFileProduct, TFileProductOption>;
	adjustmentAmount: number;
}) => {
	const fileProduct = $getProductMetadata(product);
	const subtotalAddons = {
		...BASE_SUBTOTAL_ADDONS,
		...fileProduct.subtotalAddons,
		adjustment: {
			amount: adjustmentAmount,
		},
	};

	let fileProductOptions = fileProduct.options.map((fileProductOption) =>
		$getOptionMetadata(fileProductOption, fileProduct),
	);
	fileProductOptions = fileProductOptions.map((fileProductOption) => {
		const optionPriceRatioToProduct = bigMath.div(
			fileProductOption.subtotal,
			fileProduct.subtotal,
		);

		return {
			...fileProductOption,
			subtotalAddons: {
				...fileProductOption.subtotalAddons,
				adjustment: {
					amount: bigMath.mul(optionPriceRatioToProduct, adjustmentAmount),
				},
			},
		};
	});

	return {
		...fileProduct,
		subtotalAddons,
		options: fileProductOptions,
	};
};

const applyAdjustmentToFile = <
	TFile extends FileInputLike = FileInputLike,
	TFileProduct extends FileProductInputLike = FileProductInputLike,
	TFileProductOption extends
		FileProductOptionInputLike = FileProductOptionInputLike,
>({
	file,
	adjustmentAmount,
}: {
	file: FileInputLike<TFile, TFileProduct, TFileProductOption>;
	adjustmentAmount: number;
}) => {
	let fileProducts = file.products.map($deepPopulateProductMetadata);
	const subtotal = fileProducts.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal),
		0,
	);

	let remainingAmountForDivision = adjustmentAmount;
	fileProducts = fileProducts
		.sort((a, b) => a.subtotal - b.subtotal)
		.map((fileProduct, i) => {
			const isThereAmountToDivide = remainingAmountForDivision !== 0;
			const isThisLastProduct = i === fileProducts.length - 1;

			if (isThisLastProduct) {
				const subtotalAddons = {
					...fileProduct.subtotalAddons,
					adjustment: {
						amount: isThereAmountToDivide ? remainingAmountForDivision : 0,
					},
				};

				return { ...fileProduct, subtotalAddons };
			}

			const productPriceRatioToFile = bigMath.div(
				fileProduct.subtotal,
				subtotal,
			);
			let dividedAdjustmentAmountForProduct = isThereAmountToDivide
				? bigMath.mul(adjustmentAmount, productPriceRatioToFile)
				: 0;
			dividedAdjustmentAmountForProduct =
				Math.abs(dividedAdjustmentAmountForProduct) > fileProduct.subtotal
					? fileProduct.subtotal
					: dividedAdjustmentAmountForProduct;

			const subtotalAddons = {
				...fileProduct.subtotalAddons,
				adjustment: {
					amount: dividedAdjustmentAmountForProduct,
				},
			};

			remainingAmountForDivision = bigMath.sub(
				remainingAmountForDivision,
				dividedAdjustmentAmountForProduct,
			);

			return { ...fileProduct, subtotalAddons };
		});

	// options only stats
	fileProducts = fileProducts.map((fileProduct) => ({
		...fileProduct,
		options: fileProduct.options.map((fileProductOption) => {
			const optionPriceRatioToProduct = bigMath.div(
				fileProductOption.subtotal,
				fileProduct.subtotal,
			);

			return {
				...fileProductOption,
				subtotalAddons: {
					...fileProductOption.subtotalAddons,
					adjustment: {
						amount: bigMath.mul(
							optionPriceRatioToProduct,
							fileProduct.subtotalAddons.adjustment.amount,
						),
					},
				},
			};
		}),
	}));

	const newAdjustmentAmount = fileProducts.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotalAddons.adjustment.amount),
		0,
	);

	return calculateFile({
		...file,
		products: fileProducts,
		subtotal,
		adjustmentAmount: newAdjustmentAmount,
	}).$file;
};

const applyAdjustmentToFileGroup = <T>({
	files: allFiles,
	adjustmentAmount,
	skipPaidCheck = false,
}: {
	files: (FileInputLike & T)[];
	adjustmentAmount: number;
	skipPaidCheck?: boolean;
}) => {
	const unpaidFiles = allFiles.filter((o) =>
		skipPaidCheck ? true : $isFileUnpaid(o),
	);
	const fileGroupSubTotal = unpaidFiles.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal),
		0,
	);
	let remainingAmountForDivision = adjustmentAmount || 0;
	const updatedFiles = unpaidFiles
		.sort((a, b) => a.subtotal - b.subtotal)
		.map((file, i) => {
			const isThereAmountToDivide = remainingAmountForDivision !== 0;
			const isThisLastProduct = i === unpaidFiles.length - 1;

			if (isThisLastProduct) {
				return applyAdjustmentToFile({
					file,
					adjustmentAmount: isThereAmountToDivide
						? remainingAmountForDivision
						: 0,
				});
			}

			const filePriceRatioToGroup = bigMath.div(
				file.subtotal,
				fileGroupSubTotal,
			);
			let dividedAdjustmentAmountForFile = isThereAmountToDivide
				? bigMath.mul(adjustmentAmount, filePriceRatioToGroup)
				: 0;
			dividedAdjustmentAmountForFile =
				Math.abs(dividedAdjustmentAmountForFile) > file.subtotal
					? file.subtotal
					: dividedAdjustmentAmountForFile;

			remainingAmountForDivision = bigMath.sub(
				remainingAmountForDivision,
				dividedAdjustmentAmountForFile,
			);

			return applyAdjustmentToFile({
				file,
				adjustmentAmount: dividedAdjustmentAmountForFile,
			});
		});

	return updatedFiles;
};

const applyDiscountToProduct = <
	TFileProduct extends FileProductInputLike = FileProductInputLike,
	TFileProductOption extends
		FileProductOptionInputLike = FileProductOptionInputLike,
>({
	product,
	discount,
}: {
	product: FileProductInputLike<TFileProduct, TFileProductOption>;
	discount: DiscountInput;
}) => {
	const fileProduct = $getProductMetadata(product);
	const { discountAmount } = getDiscountAmount(fileProduct.subtotal, discount);
	const subtotalAddons = {
		...fileProduct.subtotalAddons,
		discount: {
			...discount,
			isDivided: false,
			amount: discountAmount,
		},
	};

	let fileProductOptions = fileProduct.options.map((fileProductOption) =>
		$getOptionMetadata(fileProductOption, fileProduct),
	);
	fileProductOptions = fileProductOptions.map((fileProductOption) => {
		const optionPriceRatioToProduct = bigMath.div(
			fileProductOption.subtotal,
			fileProduct.subtotal,
		);

		return {
			...fileProductOption,
			subtotalAddons: {
				...fileProductOption.subtotalAddons,
				discount: {
					...discount,
					isDivided: true,
					amount: bigMath.mul(optionPriceRatioToProduct, discountAmount),
				},
			},
		};
	});

	return {
		...fileProduct,
		subtotalAddons,
		options: fileProductOptions,
	};
};

const applyDiscountToFile = <
	TFile extends FileInputLike = FileInputLike,
	TFileProduct extends FileProductInputLike = FileProductInputLike,
	TFileProductOption extends
		FileProductOptionInputLike = FileProductOptionInputLike,
>({
	file,
	discount,
	dividedAmountFromGroup,
}: {
	file: FileInputLike<TFile, TFileProduct, TFileProductOption>;
	discount: DiscountInput;
	dividedAmountFromGroup?: number;
}) => {
	let fileProducts = file.products.map($deepPopulateProductMetadata);
	const subtotal = fileProducts.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal),
		0,
	);

	if (discount.type === DISCOUNT_TYPE.PERCENTAGE) {
		fileProducts = fileProducts.map((fileProduct) => {
			const { discountAmount } = getDiscountAmount(
				fileProduct.subtotal,
				discount,
			);
			const subtotalAddons = {
				...fileProduct.subtotalAddons,
				discount: {
					...discount,
					isDivided: false,
					amount: discountAmount,
				},
			};

			return { ...fileProduct, subtotalAddons };
		});
	}

	if (discount?.type === DISCOUNT_TYPE.AMOUNT) {
		const { discountAmount } = dividedAmountFromGroup
			? { discountAmount: dividedAmountFromGroup }
			: getDiscountAmount(subtotal, discount);

		let remainingAmountForDivision = discountAmount;
		fileProducts = fileProducts
			.sort((a, b) => a.subtotal - b.subtotal)
			.map((fileProduct, i) => {
				const isThereAmountToDivide = remainingAmountForDivision > 0;
				const isThisLastProduct = i === fileProducts.length - 1;

				if (isThisLastProduct) {
					const subtotalAddons = {
						...fileProduct.subtotalAddons,
						discount: {
							...discount,
							isDivided: true,
							amount: isThereAmountToDivide ? remainingAmountForDivision : 0,
						},
					};

					return { ...fileProduct, subtotalAddons };
				}

				const productPriceRatioToFile = bigMath.div(
					fileProduct.subtotal,
					subtotal,
				);
				let dividedDiscountAmountForProduct = isThereAmountToDivide
					? bigMath.mul(discountAmount, productPriceRatioToFile)
					: 0;
				dividedDiscountAmountForProduct =
					dividedDiscountAmountForProduct > (fileProduct.subtotal || 0)
						? fileProduct.subtotal || 0
						: dividedDiscountAmountForProduct;

				const subtotalAddons = {
					...fileProduct.subtotalAddons,
					discount: {
						...discount,
						isDivided: true,
						amount: dividedDiscountAmountForProduct,
					},
				};

				remainingAmountForDivision = bigMath.sub(
					remainingAmountForDivision,
					dividedDiscountAmountForProduct,
				);

				return { ...fileProduct, subtotalAddons };
			});
	}

	// options only stats
	fileProducts = fileProducts.map((fileProduct) => ({
		...fileProduct,
		options: fileProduct.options.map((fileProductOption) => {
			const optionPriceRatioToProduct = bigMath.div(
				fileProductOption.subtotal,
				fileProduct.subtotal,
			);

			return {
				...fileProductOption,
				subtotalAddons: {
					...fileProductOption.subtotalAddons,
					discount: {
						...discount,
						isDivided: true,
						amount: bigMath.mul(
							optionPriceRatioToProduct,
							fileProduct.subtotalAddons.discount.amount,
						),
					},
				},
			};
		}),
	}));

	const newDiscountAmount = fileProducts.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotalAddons.discount.amount || 0),
		0,
	);

	return calculateFile({
		...file,
		products: fileProducts,
		subtotal,
		discountAmount: newDiscountAmount,
	}).$file;
};

const applyDiscountToFileGroup = <
	TFile extends FileInputLike = FileInputLike,
	TFileProduct extends FileProductInputLike = FileProductInputLike,
	TFileProductOption extends
		FileProductOptionInputLike = FileProductOptionInputLike,
>({
	files: allFiles,
	discount,
	skipPaidCheck = false,
}: {
	files: FileInputLike<TFile, TFileProduct, TFileProductOption>[];
	discount: DiscountInput;
	skipPaidCheck?: boolean;
}) => {
	const unpaidFiles = allFiles.filter((o) =>
		skipPaidCheck ? true : $isFileUnpaid(o),
	);
	let updatedFiles: FileInputLike[] = [];

	if (discount.type === DISCOUNT_TYPE.PERCENTAGE) {
		updatedFiles = unpaidFiles.map((file) =>
			applyDiscountToFile({ file, discount }),
		);
	}

	if (discount.type === DISCOUNT_TYPE.AMOUNT) {
		const fileGroupSubTotal = unpaidFiles.reduce(
			(pre, cur) => bigMath.add(pre, cur.subtotal),
			0,
		);
		const { discountAmount } = getDiscountAmount(fileGroupSubTotal, discount);

		let remainingAmountForDivision = discountAmount;
		updatedFiles = unpaidFiles
			.sort((a, b) => a.subtotal - b.subtotal)
			.map((file, i) => {
				const isThereAmountToDivide = remainingAmountForDivision > 0;
				const isThisLastProduct = i === unpaidFiles.length - 1;

				if (isThisLastProduct) {
					return applyDiscountToFile({
						file,
						discount,
						dividedAmountFromGroup: isThereAmountToDivide
							? remainingAmountForDivision
							: 0,
					});
				}

				const filePriceRatioToGroup = bigMath.div(
					file.subtotal,
					fileGroupSubTotal,
				);
				const dividedDiscountAmountForFile = isThereAmountToDivide
					? bigMath.mul(discountAmount, filePriceRatioToGroup)
					: 0;

				remainingAmountForDivision = bigMath.sub(
					remainingAmountForDivision,
					dividedDiscountAmountForFile,
				);

				return applyDiscountToFile({
					file,
					discount,
					dividedAmountFromGroup: dividedDiscountAmountForFile,
				});
			});
	}

	return updatedFiles;
};

const applyNonReceiptOfferToFile = <
	TFile extends FileInputLike = FileInputLike,
	TFileProduct extends FileProductInputLike = FileProductInputLike,
	TFileProductOption extends
		FileProductOptionInputLike = FileProductOptionInputLike,
>({
	file,
	offer,
}: {
	file: FileInputLike<TFile, TFileProduct, TFileProductOption>;
	offer: OfferLike;
}) => {
	let fileProducts = file.products.map($deepPopulateProductMetadata);
	const subtotal = fileProducts.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal),
		0,
	);

	const { eligibleProducts } = getAppliedOfferByProducts(fileProducts, offer);

	fileProducts = fileProducts.map((fileProduct) => ({
		...fileProduct,
		subtotalAddons: {
			...fileProduct.subtotalAddons,
			offer: {
				amount: eligibleProducts[fileProduct._id.toString()] || 0,
				metadata: eligibleProducts[fileProduct._id.toString()]
					? offer
					: undefined,
			},
		},
	}));

	// options only stats
	fileProducts = fileProducts.map((fileProduct) => ({
		...fileProduct,
		options: fileProduct.options.map((fileProductOption) => {
			const productEligibleAmount =
				eligibleProducts[fileProduct._id.toString()] || 0;
			const optionPriceRatioToProduct = bigMath.div(
				fileProductOption.subtotal,
				fileProduct.subtotal,
			);
			const optionEligibleAmount = bigMath.mul(
				optionPriceRatioToProduct,
				productEligibleAmount,
			);

			return {
				...fileProductOption,
				subtotalAddons: {
					...fileProductOption.subtotalAddons,
					offer: {
						amount: optionEligibleAmount,
						metadata: optionEligibleAmount ? offer : undefined,
					},
				},
			};
		}),
	}));

	const offerAmount = fileProducts.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotalAddons.offer.amount),
		0,
	);

	return calculateFile({
		...file,
		products: fileProducts,
		subtotal,
		offerAmount,
	}).$file;
};

const applyNonReceiptOfferToFileGroup = <
	TFile extends FileInputLike = FileInputLike,
	TFileProduct extends FileProductInputLike = FileProductInputLike,
	TFileProductOption extends
		FileProductOptionInputLike = FileProductOptionInputLike,
>({
	files,
	offer,
	skipPaidCheck = false,
}: {
	files: FileInputLike<TFile, TFileProduct, TFileProductOption>[];
	offer: OfferLike;
	skipPaidCheck?: boolean;
}) => {
	return files
		.filter((o) => (skipPaidCheck ? true : $isFileUnpaid(o)))
		.map((file) => applyNonReceiptOfferToFile({ file, offer }));
};

const applyReceiptOfferToFile = <
	TFile extends FileInputLike = FileInputLike,
	TFileProduct extends FileProductInputLike = FileProductInputLike,
	TFileProductOption extends
		FileProductOptionInputLike = FileProductOptionInputLike,
>({
	file,
	offer,
	dividedAmountFromGroup,
}: {
	file: FileInputLike<TFile, TFileProduct, TFileProductOption>;
	offer: OfferLike;
	dividedAmountFromGroup?: number;
}) => {
	let fileProducts = file.products.map($deepPopulateProductMetadata);
	const subtotal = fileProducts.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal),
		0,
	);

	const { offerAmount } = dividedAmountFromGroup
		? { offerAmount: dividedAmountFromGroup }
		: getAppliedOfferByReceipt(subtotal, offer);

	let remainingAmountForDivision = offerAmount;
	fileProducts = fileProducts
		.sort((a, b) => a.subtotal - b.subtotal)
		.map((fileProduct, i) => {
			const isThereAmountToDivide = remainingAmountForDivision > 0;
			const isThisLastProduct = i === fileProducts.length - 1;

			if (isThisLastProduct) {
				const subtotalAddons = {
					...fileProduct.subtotalAddons,
					offer: {
						amount: isThereAmountToDivide ? remainingAmountForDivision : 0,
						metadata: isThereAmountToDivide ? offer : undefined,
					},
				};

				return { ...fileProduct, subtotalAddons };
			}

			const productPriceRatioToFile = bigMath.div(
				fileProduct.subtotal,
				subtotal,
			);
			let dividedOfferAmountForProduct = isThereAmountToDivide
				? bigMath.mul(offerAmount, productPriceRatioToFile)
				: 0;
			dividedOfferAmountForProduct =
				dividedOfferAmountForProduct > fileProduct.subtotal
					? fileProduct.subtotal
					: dividedOfferAmountForProduct;

			const subtotalAddons = {
				...fileProduct.subtotalAddons,
				offer: {
					amount: dividedOfferAmountForProduct,
					metadata: dividedOfferAmountForProduct ? offer : undefined,
				},
			};

			remainingAmountForDivision = bigMath.sub(
				remainingAmountForDivision,
				dividedOfferAmountForProduct,
			);

			return { ...fileProduct, subtotalAddons };
		});

	// options only stats
	fileProducts = fileProducts.map((fileProduct) => ({
		...fileProduct,
		options: fileProduct.options.map((fileProductOption) => {
			const optionPriceRatioToProduct = bigMath.div(
				fileProductOption.subtotal,
				fileProduct.subtotal,
			);
			const dividedOfferAmountForProduct = bigMath.mul(
				optionPriceRatioToProduct,
				fileProduct.subtotalAddons.offer.amount,
			);

			return {
				...fileProductOption,
				subtotalAddons: {
					...fileProductOption.subtotalAddons,
					offer: {
						amount: dividedOfferAmountForProduct,
						metadata: dividedOfferAmountForProduct ? offer : undefined,
					},
				},
			};
		}),
	}));

	const newOfferAmount = fileProducts.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotalAddons.offer.amount),
		0,
	);

	return calculateFile({
		...file,
		products: fileProducts,
		subtotal,
		offerAmount: newOfferAmount,
	}).$file;
};

const applyReceiptOfferToFileGroup = <
	TFile extends FileInputLike = FileInputLike,
	TFileProduct extends FileProductInputLike = FileProductInputLike,
	TFileProductOption extends
		FileProductOptionInputLike = FileProductOptionInputLike,
>({
	files: allFiles,
	offer,
	skipPaidCheck = false,
}: {
	files: FileInputLike<TFile, TFileProduct, TFileProductOption>[];
	offer: OfferLike;
	skipPaidCheck?: boolean;
}) => {
	const unpaidFiles = allFiles.filter((o) =>
		skipPaidCheck ? true : $isFileUnpaid(o),
	);
	const fileGroupSubTotal = unpaidFiles.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal),
		0,
	);
	const { offerAmount } = getAppliedOfferByReceipt(fileGroupSubTotal, offer);

	let remainingAmountForDivision = offerAmount;
	const updatedFiles = unpaidFiles
		.sort((a, b) => (a.subtotal || 0) - (b.subtotal || 0))
		.map((file, i) => {
			const isThereAmountToDivide = remainingAmountForDivision > 0;
			const isThisLastProduct = i === unpaidFiles.length - 1;

			if (isThisLastProduct) {
				return applyReceiptOfferToFile({
					file,
					offer,
					dividedAmountFromGroup: isThereAmountToDivide
						? remainingAmountForDivision
						: 0,
				});
			}

			const filePriceRatioToGroup = bigMath.div(
				file.subtotal || 0,
				fileGroupSubTotal,
			);
			const dividedOfferAmountForFile = isThereAmountToDivide
				? bigMath.mul(offerAmount, filePriceRatioToGroup)
				: 0;

			remainingAmountForDivision = bigMath.sub(
				remainingAmountForDivision,
				dividedOfferAmountForFile,
			);

			return applyReceiptOfferToFile({
				file,
				offer,
				dividedAmountFromGroup: dividedOfferAmountForFile,
			});
		});

	return updatedFiles;
};

const applyVatToFile = <
	TFile extends FileInputLike = FileInputLike,
	TFileProduct extends FileProductInputLike = FileProductInputLike,
	TFileProductOption extends
		FileProductOptionInputLike = FileProductOptionInputLike,
>({
	file,
	vat,
}: {
	file: FileInputLike<TFile, TFileProduct, TFileProductOption>;
	vat: number;
}) => {
	let fileProducts = file.products.map($deepPopulateProductMetadata);
	const subtotal = fileProducts.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal),
		0,
	);

	fileProducts = fileProducts.map((fileProduct) => {
		const isIncluded =
			!!fileProduct.originalPriceAddons.vat.percentage &&
			!!fileProduct.originalPriceAddons.vat.amount;
		const vatPercentage = isIncluded
			? fileProduct.originalPriceAddons.vat.percentage
			: fileProduct.noVat
				? 0
				: vat;

		const grossSales = bigMath.sub(
			fileProduct.subtotal,
			fileProduct.subtotalAddons.offer.amount,
			fileProduct.subtotalAddons.discount.amount,
		);
		const addedVatScRate = vatPercentage;
		const vatAmount = new Big(
			new Big(
				new Big(grossSales).sub(
					new Big(grossSales).div(new Big(1).add(addedVatScRate)),
				),
			).mul(vatPercentage),
		)
			.div(addedVatScRate || 1)
			.round(2)
			.toNumber();

		const subtotalAddons = {
			...fileProduct.subtotalAddons,
			vat: {
				isIncluded: isIncluded,
				percentage: vatPercentage,
				amount: vatAmount,
			},
		};

		return { ...fileProduct, subtotalAddons };
	});

	// options only stats
	fileProducts = fileProducts.map((fileProduct) => ({
		...fileProduct,
		options: fileProduct.options.map((fileProductOption) => {
			const isIncluded =
				!!fileProductOption.originalPriceAddons.vat.percentage &&
				!!fileProductOption.originalPriceAddons.vat.amount;
			const vatPercentage = isIncluded
				? fileProductOption.originalPriceAddons.vat.percentage
				: fileProduct.noVat
					? 0
					: vat;

			const grossSales = bigMath.sub(
				fileProductOption.subtotal,
				fileProductOption.subtotalAddons.offer.amount,
				fileProductOption.subtotalAddons.discount.amount,
			);
			const addedVatScRate = vatPercentage;
			const vatAmount = new Big(
				new Big(
					new Big(grossSales).sub(
						new Big(grossSales).div(new Big(1).add(addedVatScRate)),
					),
				).mul(vatPercentage),
			)
				.div(addedVatScRate || 1)
				.round(2)
				.toNumber();

			return {
				...fileProductOption,
				subtotalAddons: {
					...fileProductOption.subtotalAddons,
					vat: {
						isIncluded: isIncluded,
						percentage: vatPercentage,
						amount: vatAmount,
					},
				},
			};
		}),
	}));

	const newVatAmount = fileProducts.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotalAddons.vat.amount),
		0,
	);

	return calculateFile({
		...file,
		products: fileProducts,
		subtotal,
		vatAmount: newVatAmount,
	}).$file;
};

const applyVatToFileGroup = <
	TFile extends FileInputLike = FileInputLike,
	TFileProduct extends FileProductInputLike = FileProductInputLike,
	TFileProductOption extends
		FileProductOptionInputLike = FileProductOptionInputLike,
>({
	files,
	vat,
	skipPaidCheck = false,
}: {
	files: FileInputLike<TFile, TFileProduct, TFileProductOption>[];
	vat: number;
	skipPaidCheck?: boolean;
}) => {
	return files
		.filter((o) => (skipPaidCheck ? true : $isFileUnpaid(o)))
		.map((file) => applyVatToFile({ file, vat }));
};

const calculateFile = <
	TFile extends FileInputLike = FileInputLike,
	TFileProduct extends FileProductInputLike = FileProductInputLike,
	TFileProductOption extends
		FileProductOptionInputLike = FileProductOptionInputLike,
>(
	file: FileInputLike<TFile, TFileProduct, TFileProductOption>,
) => {
	let fileProducts = file.products.map($deepPopulateProductMetadata);
	fileProducts = fileProducts.map((fileProduct) => {
		const subtotalAddons = $getSubtotalAddons({
			product: fileProduct,
			isCancelled: file.isCancelled,
		});
		const { netAmount, grossAmount } = $getProductWithNetAndGrossAmount({
			...fileProduct,
			subtotalAddons,
		});
		const cancelledAmount = 0;

		return {
			...fileProduct,
			subtotalAddons,
			netAmount,
			grossAmount,
			cancelledAmount,
		};
	});

	fileProducts = fileProducts.map((fileProduct) => ({
		...fileProduct,
		options: fileProduct.options.map((fileProductOption) => {
			const subtotalAddons = $getOptionSubtotalAddons({
				option: fileProductOption,
				product: fileProduct,
				isCancelled: file.isCancelled,
			});
			const { netAmount, grossAmount } = $getOptionWithNetAndGrossAmount(
				{
					...fileProductOption,
					subtotalAddons,
				},
				fileProduct,
			);
			const cancelledAmount = 0;

			return {
				...fileProductOption,
				subtotalAddons,
				netAmount,
				grossAmount,
				cancelledAmount,
			};
		}),
	}));

	let cancelledProducts = file.cancelledProducts.map(
		$deepPopulateProductMetadata,
	);
	cancelledProducts = cancelledProducts.map((fileProduct) => {
		const subtotal = 0;
		const subtotalAddons = $getSubtotalAddons({
			product: { ...fileProduct, subtotal },
			isCancelled: true,
		});
		const netAmount = 0;
		const grossAmount = 0;
		const cancelledAmount = bigMath.sub(
			$getProductSubtotal({ ...fileProduct, subtotal, netAmount, grossAmount }),
			$getProductTotalRawPriceAddons({
				...fileProduct,
				subtotal,
				netAmount,
				grossAmount,
			}),
		); // to exclude any buried in amount so this is only `net` amount

		return {
			...fileProduct,
			subtotal,
			subtotalAddons,
			netAmount,
			grossAmount,
			cancelledAmount,
		};
	});
	cancelledProducts = cancelledProducts.map((fileProduct) => ({
		...fileProduct,
		options: fileProduct.options.map((fileProductOption) => {
			const subtotal = 0;
			const subtotalAddons = $getOptionSubtotalAddons({
				option: { ...fileProductOption, subtotal },
				product: fileProduct,
				isCancelled: true,
			});
			const netAmount = 0;
			const grossAmount = 0;
			const cancelledAmount = bigMath.sub(
				$getOptionSubtotal(
					{ ...fileProductOption, subtotal, netAmount, grossAmount },
					fileProduct,
				),
				$getOptionTotalRawPriceAddons(
					{
						...fileProductOption,
						subtotal,
						netAmount,
						grossAmount,
					},
					fileProduct,
				),
			); // to exclude any buried in amount so this is only `net` amount

			return {
				...fileProductOption,
				subtotal,
				subtotalAddons,
				netAmount,
				grossAmount,
				cancelledAmount,
			};
		}),
	}));

	const subtotal = fileProducts.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotal),
		0,
	);
	const offerAmount = fileProducts.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotalAddons.offer.amount),
		0,
	);
	const discountAmount = fileProducts.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotalAddons.discount.amount),
		0,
	);
	const netAmount = fileProducts.reduce(
		(pre, cur) => bigMath.add(pre, cur.netAmount),
		0,
	);
	const vatAmount = fileProducts.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotalAddons.vat.amount),
		0,
	);
	const adjustmentAmount = fileProducts.reduce(
		(pre, cur) => bigMath.add(pre, cur.subtotalAddons.adjustment.amount),
		0,
	);
	const grossAmount = fileProducts.reduce(
		(pre, cur) => bigMath.add(pre, cur.grossAmount),
		0,
	);
	const cancelledAmount = cancelledProducts.reduce(
		(pre, cur) => bigMath.add(pre, cur.cancelledAmount),
		0,
	);

	const $file = {
		...file,
		products: fileProducts,
		cancelledProducts,
	};

	return {
		$file,
		$isAllProductsWithVat:
			!!fileProducts.length &&
			fileProducts.every((product) => !!product.subtotalAddons.vat.amount),
		$isSomeProductsWithVat:
			!!fileProducts.length &&
			fileProducts.some((product) => !!product.subtotalAddons.vat.amount),
		products: fileProducts,
		cancelledProducts,
		subtotal,
		offerAmount,
		discountAmount,
		netAmount,
		vatAmount,
		adjustmentAmount,
		grossAmount,
		cancelledAmount,
	};
};

const applyVenueAddonsToProduct = <TObj, TVenue>({
	obj,
	venue,
}: {
	obj: { originalPrice: number } & TObj;
	venue: VenueLike & TVenue;
}) => {
	const { vat, isVatBuried } = venue;
	const { originalPrice } = obj;

	const vatAddOn = isVatBuried ? bigMath.mul(originalPrice, vat) : 0;
	const listedPrice = bigMath.add(originalPrice, vatAddOn);

	const newListedPrice = listedPrice;
	const originalPriceAddons = {
		vat: { amount: vatAddOn, percentage: vat },
	};

	return { ...obj, listedPrice: newListedPrice, originalPriceAddons };
};

const calculateFileGroup = <
	TFile extends FileInputLike = FileInputLike,
	TFileProduct extends FileProductInputLike = FileProductInputLike,
	TFileProductOption extends
		FileProductOptionInputLike = FileProductOptionInputLike,
>(
	files: FileInputLike<TFile, TFileProduct, TFileProductOption>[],
) => {
	return files.reduce(
		(pre, cur) => {
			const isFileUnpaid = $isFileUnpaid(cur);
			const isFilePaid = !isFileUnpaid;

			return {
				all: $addFileToFileGroupCalculation(pre.all, cur),
				paid: isFilePaid
					? $addFileToFileGroupCalculation(pre.paid, cur)
					: pre.paid,
				unpaid: isFileUnpaid
					? $addFileToFileGroupCalculation(pre.unpaid, cur)
					: pre.unpaid,
			};
		},
		{
			all: baseFileGroupValue,
			paid: baseFileGroupValue,
			unpaid: baseFileGroupValue,
		},
	);
};

const $getProductWithNetAndGrossAmount = <
	TFileProduct extends FileProductInputLike = FileProductInputLike,
	TFileProductOption extends
		FileProductOptionInputLike = FileProductOptionInputLike,
>(
	product: FileProductInputLike<TFileProduct, TFileProductOption>,
) => {
	product = {
		...product,
		subtotalAddons: {
			...BASE_SUBTOTAL_ADDONS,
			...product.subtotalAddons,
		},
	};

	const isVatIncluded =
		!!product.originalPriceAddons.vat.percentage &&
		!!product.originalPriceAddons.vat.amount;

	let [grossAmount, netAmount] = [0, 0];

	if (isVatIncluded) {
		grossAmount = bigMath.add(
			bigMath.sub(
				product.subtotal,
				product.subtotalAddons.offer.amount,
				product.subtotalAddons.discount.amount,
			),
			product.subtotalAddons.adjustment.amount,
		);
		netAmount = bigMath.sub(grossAmount, product.subtotalAddons.vat.amount);
	} else {
		netAmount = bigMath.sub(
			product.subtotal,
			$getProductTotalRawPriceAddons(product),
			product.subtotalAddons.offer.amount,
			product.subtotalAddons.discount.amount,
		);

		grossAmount = bigMath.add(
			netAmount,
			product.subtotalAddons.vat.amount,
			product.subtotalAddons.adjustment.amount,
		);
	}

	return { ...product, netAmount, grossAmount };
};

const $getOptionWithNetAndGrossAmount = <
	TFileOption extends FileProductOptionInputLike = FileProductOptionInputLike,
	TFileProduct extends FileProductInputLike = FileProductInputLike,
>(
	option: FileProductOptionInputLike<TFileOption>,
	product: FileProductInputLike<TFileProduct, TFileOption>,
) => {
	option = {
		...option,
		subtotalAddons: {
			...BASE_SUBTOTAL_ADDONS,
			...option.subtotalAddons,
		},
	};

	const isVatIncluded =
		!!option.originalPriceAddons.vat.percentage &&
		!!option.originalPriceAddons.vat.amount;

	let [grossAmount, netAmount] = [0, 0];

	if (isVatIncluded) {
		grossAmount = bigMath.add(
			bigMath.sub(
				option.subtotal,
				option.subtotalAddons.offer.amount,
				option.subtotalAddons.discount.amount,
			),
			option.subtotalAddons.adjustment.amount,
		);
		netAmount = bigMath.sub(grossAmount, option.subtotalAddons.vat.amount);
	} else {
		netAmount = bigMath.sub(
			option.subtotal,
			$getOptionTotalRawPriceAddons(option, product),
			option.subtotalAddons.offer.amount,
			option.subtotalAddons.discount.amount,
		);

		grossAmount = bigMath.add(
			netAmount,
			option.subtotalAddons.vat.amount,
			option.subtotalAddons.adjustment.amount,
		);
	}

	return { ...option, netAmount, grossAmount };
};

const getOffersUsedFromFiles = <
	TFile extends FileInputLike = FileInputLike,
	TFileProduct extends FileProductInputLike = FileProductInputLike,
	TFileProductOption extends
		FileProductOptionInputLike = FileProductOptionInputLike,
>(
	files: FileInputLike<TFile, TFileProduct, TFileProductOption>[],
) => {
	const offersUsedDictionary: Record<
		string,
		{ offer: ObjectLike; amount: number }
	> = {};

	for (const i of files.map((o) => o.products).flat()) {
		if (i.subtotalAddons?.offer.amount && i.subtotalAddons.offer.metadata) {
			const key = (i.subtotalAddons.offer.metadata as Offer)._id;

			if (offersUsedDictionary[key]) {
				offersUsedDictionary[key].amount = bigMath.add(
					offersUsedDictionary[key].amount,
					i.subtotalAddons?.offer?.amount,
				);
			} else {
				offersUsedDictionary[key] = {
					offer: i.subtotalAddons.offer.metadata,
					amount: i.subtotalAddons.offer.amount,
				};
			}
		}
	}

	return Object.values(offersUsedDictionary);
};

export {
	applyAdjustmentToFile,
	applyAdjustmentToFileGroup,
	applyAdjustmentToProduct,
	applyDiscountToFile,
	applyDiscountToFileGroup,
	applyDiscountToProduct,
	applyNonReceiptOfferToFile,
	applyNonReceiptOfferToFileGroup,
	applyReceiptOfferToFile,
	applyReceiptOfferToFileGroup,
	applyVatToFile,
	applyVatToFileGroup,
	applyVenueAddonsToProduct,
	calculateFile,
	calculateFileGroup,
	getOffersUsedFromFiles,
	$getProductWithNetAndGrossAmount as getProductWithNetAndGrossAmount,
	$getSubtotalAddons as getSubtotalAddons,
};

const $deepPopulateProductMetadata = <
	TFileProduct extends FileProductInputLike = FileProductInputLike,
	TFileProductOption extends
		FileProductOptionInputLike = FileProductOptionInputLike,
>(
	fileProduct,
): FileProductInputLike<
	TFileProduct & FileProductCalculatedValues,
	TFileProductOption & FileProductOptionCalculatedValues
> => {
	const updatedProduct = $getProductMetadata(fileProduct);
	return {
		...updatedProduct,
		options: updatedProduct.options.map((fileProductOption) =>
			$getOptionMetadata(fileProductOption, updatedProduct),
		),
	};
};

const $getOptionServingQuantity = <
	TFileOption extends FileProductOptionInputLike = FileProductOptionInputLike,
	TFileProduct extends FileProductInputLike = FileProductInputLike,
>(
	option: FileProductOptionInputLike<TFileOption>,
	product: FileProductInputLike<TFileProduct, TFileOption>,
) => bigMath.mul(option.quantity, product.quantity);

const $getOptionSubtotal = <
	TFileOption extends FileProductOptionInputLike = FileProductOptionInputLike,
	TFileProduct extends FileProductInputLike = FileProductInputLike,
>(
	option: FileProductOptionInputLike<TFileOption>,
	product: FileProductInputLike<TFileProduct, TFileOption>,
) =>
	bigMath.mul(option.listedPrice, $getOptionServingQuantity(option, product));

const $getOptionMetadata = <
	TFileOption extends FileProductOptionInputLike = FileProductOptionInputLike,
	TFileProduct extends FileProductInputLike = FileProductInputLike,
>(
	option: FileProductOptionInputLike<TFileOption>,
	product: FileProductInputLike<TFileProduct, TFileOption>,
): FileProductOptionInputLike<
	TFileOption & FileProductOptionCalculatedValues
> => ({
	netAmount: 0,
	grossAmount: 0,
	cancelledAmount: 0,
	...option,
	subtotal: $getOptionSubtotal(option, product),
	subtotalAddons: {
		...BASE_SUBTOTAL_ADDONS,
		...option.subtotalAddons,
	},
});

const $getProductMetadata = <
	TFileProduct extends FileProductInputLike = FileProductInputLike,
	TFileProductOption extends
		FileProductOptionInputLike = FileProductOptionInputLike,
>(
	product: FileProductInputLike<TFileProduct, TFileProductOption>,
): FileProductInputLike<
	TFileProduct & FileProductCalculatedValues,
	TFileProductOption
> => ({
	netAmount: 0,
	grossAmount: 0,
	cancelledAmount: 0,
	...product,
	unitPrice: $getProductUnitPrice(product),
	subtotal: $getProductSubtotal(product),
	subtotalAddons: {
		...BASE_SUBTOTAL_ADDONS,
		...product.subtotalAddons,
	},
});

const $isFileUnpaid = <
	TFile extends FileInputLike = FileInputLike,
	TFileProduct extends FileProductInputLike = FileProductInputLike,
	TFileProductOption extends
		FileProductOptionInputLike = FileProductOptionInputLike,
>(
	file: FileInputLike<TFile, TFileProduct, TFileProductOption>,
) => !file.receipt && !file.isCancelled;

const $getSubtotalAddons = <
	TFileProduct extends
		FileProductInputLike<FileProductCalculatedValues> = FileProductInputLike<FileProductCalculatedValues>,
	TFileProductOption extends
		FileProductOptionInputLike<FileProductOptionCalculatedValues> = FileProductOptionInputLike<FileProductOptionCalculatedValues>,
>({
	product,
	isCancelled,
}: {
	product: FileProductInputLike<TFileProduct, TFileProductOption>;
	isCancelled: boolean;
}) => {
	if (isCancelled) return BASE_SUBTOTAL_ADDONS;

	product = {
		...product,
		subtotalAddons: {
			...BASE_SUBTOTAL_ADDONS,
			...product.subtotalAddons,
		},
	};
	const { originalPriceAddons, subtotal, subtotalAddons } = product;

	const newOfferMetadata = subtotalAddons.offer.metadata;
	const newOfferAmount = newOfferMetadata
		? isReceiptOffer(newOfferMetadata)
			? subtotalAddons.offer.amount
			: getAppliedOfferByProducts([product], newOfferMetadata).offerAmount
		: 0;

	const newDiscountIsDivided = subtotalAddons.discount.isDivided;
	const newDiscountType = subtotalAddons.discount.type;
	const newDiscountValue = subtotalAddons.discount.value;
	const newDiscountAmount =
		newDiscountType === DISCOUNT_TYPE.PERCENTAGE || !newDiscountIsDivided
			? getDiscountAmount(subtotal, {
					type: newDiscountType,
					value: newDiscountValue,
				}).discountAmount
			: subtotalAddons.discount.amount;

	const newVatIsIncluded =
		!!originalPriceAddons.vat.percentage && !!originalPriceAddons.vat.amount;
	const newVatPercentage = newVatIsIncluded
		? originalPriceAddons.vat.percentage
		: subtotalAddons.vat.percentage;

	let [newVatAmount] = [0];
	const grossSales = bigMath.sub(
		subtotal,
		subtotalAddons.offer.amount,
		subtotalAddons.discount.amount,
	);
	const addedVatScRate = bigMath.add(newVatPercentage);
	newVatAmount = newVatIsIncluded
		? new Big(
				new Big(
					new Big(grossSales).sub(
						new Big(grossSales).div(new Big(1).add(addedVatScRate)),
					),
				).mul(newVatPercentage),
			)
				.div(addedVatScRate || 1)
				.round(2)
				.toNumber()
		: bigMath.mul(
				bigMath.sub(
					subtotal,
					$getProductTotalRawPriceAddons(product),
					subtotalAddons.offer.amount,
					subtotalAddons.discount.amount,
				),
				newVatPercentage,
			);

	return {
		offer: {
			amount: newOfferAmount,
			metadata: newOfferAmount ? newOfferMetadata : undefined,
		},
		discount: {
			isDivided: newDiscountIsDivided,
			type: newDiscountType,
			value: newDiscountValue,
			amount: newDiscountAmount,
		},
		vat: {
			isIncluded: newVatIsIncluded,
			percentage: newVatPercentage,
			amount: newVatAmount,
		},
		adjustment: {
			amount: subtotalAddons.adjustment.amount,
		},
	};
};

const $getOptionSubtotalAddons = <
	TFileOption extends FileProductOptionInputLike = FileProductOptionInputLike,
	TFileProduct extends FileProductInputLike = FileProductInputLike,
>({
	option,
	product,
	isCancelled,
}: {
	option: FileProductOptionInputLike<TFileOption>;
	product: FileProductInputLike<TFileProduct, TFileOption>;
	isCancelled: boolean;
}) => {
	if (isCancelled) return BASE_SUBTOTAL_ADDONS;

	const fileProduct = $getProductMetadata(product);
	const fileProductOption = $getOptionMetadata(option, fileProduct);

	const { subtotal: productSubtotal, subtotalAddons: productSubtotalAddons } =
		fileProduct;
	const {
		originalPriceAddons: optionOriginalPriceAddons,
		subtotal: optionSubtotal,
		subtotalAddons: optionSubtotalAddons,
	} = fileProductOption;

	const optionPriceRatioToProduct = bigMath.div(
		optionSubtotal,
		productSubtotal,
	);

	const newOfferMetadata = productSubtotalAddons.offer.metadata;
	const productOfferAmount = productSubtotalAddons.offer.amount;
	const newOfferAmount = bigMath.mul(
		optionPriceRatioToProduct,
		productOfferAmount,
	);

	const newDiscountIsDivided = true;
	const newDiscountType = productSubtotalAddons.discount.type;
	const newDiscountValue = productSubtotalAddons.discount.value;
	const newDiscountAmount = bigMath.mul(
		optionPriceRatioToProduct,
		productSubtotalAddons.discount.amount,
	);

	const newAdjustmentAmount = bigMath.mul(
		optionPriceRatioToProduct,
		productSubtotalAddons.adjustment.amount,
	);

	const newVatIsIncluded =
		!!optionOriginalPriceAddons.vat.percentage &&
		!!optionOriginalPriceAddons.vat.amount;
	const newVatPercentage = newVatIsIncluded
		? optionOriginalPriceAddons.vat.percentage
		: optionSubtotalAddons.vat.percentage;

	let [newVatAmount] = [0];
	const grossSales = bigMath.sub(
		optionSubtotal,
		optionSubtotalAddons.offer.amount,
		optionSubtotalAddons.discount.amount,
	);
	const addedVatScRate = bigMath.add(newVatPercentage);
	newVatAmount = newVatIsIncluded
		? new Big(
				new Big(
					new Big(grossSales).sub(
						new Big(grossSales).div(new Big(1).add(addedVatScRate)),
					),
				).mul(newVatPercentage),
			)
				.div(addedVatScRate || 1)
				.round(2)
				.toNumber()
		: bigMath.mul(
				bigMath.sub(
					optionSubtotal,
					$getOptionTotalRawPriceAddons(fileProductOption, fileProduct),
					optionSubtotalAddons.offer.amount,
					optionSubtotalAddons.discount.amount,
				),
				newVatPercentage,
			);

	return {
		offer: {
			amount: newOfferAmount,
			metadata: newOfferAmount ? newOfferMetadata : undefined,
		},
		discount: {
			isDivided: newDiscountIsDivided,
			type: newDiscountType,
			value: newDiscountValue,
			amount: newDiscountAmount,
		},
		vat: {
			isIncluded: newVatIsIncluded,
			percentage: newVatPercentage,
			amount: newVatAmount,
		},
		adjustment: {
			amount: newAdjustmentAmount,
		},
	};
};

const $getProductSubtotal = <
	TFileProduct extends FileProductInputLike = FileProductInputLike,
	TFileProductOption extends
		FileProductOptionInputLike = FileProductOptionInputLike,
>(
	product: FileProductInputLike<TFileProduct, TFileProductOption>,
) => {
	return new Big($getProductUnitPrice(product))
		.times(product.quantity || 0)
		.round(2)
		.toNumber();
};

const $getProductUnitPrice = <
	TFileProduct extends FileProductInputLike = FileProductInputLike,
	TFileProductOption extends
		FileProductOptionInputLike = FileProductOptionInputLike,
>(
	product: FileProductInputLike<TFileProduct, TFileProductOption>,
) => {
	return new Big(product.listedPrice || 0)
		.add(
			product.options.reduce<BigSource>(
				(pre, cur) =>
					new Big(pre).add(
						new Big(cur.listedPrice || 0).mul(cur.quantity || 1),
					),
				0,
			),
		)
		.round(2)
		.toNumber();
};

const $getProductTotalRawPriceAddons = <
	TFileProduct extends FileProductInputLike = FileProductInputLike,
	TFileProductOption extends
		FileProductOptionInputLike = FileProductOptionInputLike,
>(
	product: FileProductInputLike<TFileProduct, TFileProductOption>,
) => {
	return new Big(product.originalPriceAddons.vat.amount)
		.add(
			product.options.reduce<BigSource>(
				(pre, cur) => new Big(pre).add(cur.originalPriceAddons.vat.amount),
				0,
			),
		)
		.times(product.quantity)
		.round(2)
		.toNumber();
};

const $getOptionTotalRawPriceAddons = <
	TFileOption extends FileProductOptionInputLike = FileProductOptionInputLike,
	TFileProduct extends FileProductInputLike = FileProductInputLike,
>(
	option: FileProductOptionInputLike<TFileOption>,
	product: FileProductInputLike<TFileProduct, TFileOption>,
) => {
	return new Big(option.originalPriceAddons.vat.amount)
		.times($getOptionServingQuantity(option, product))
		.round(2)
		.toNumber();
};

const $addFileToFileGroupCalculation = <
	TFile extends FileInputLike = FileInputLike,
	TFileProduct extends FileProductInputLike = FileProductInputLike,
	TFileProductOption extends
		FileProductOptionInputLike = FileProductOptionInputLike,
>(
	group,
	file: FileInputLike<TFile, TFileProduct, TFileProductOption>,
) => {
	const {
		$file,
		$isAllProductsWithVat,
		$isSomeProductsWithVat,
		products,
		cancelledProducts,
		subtotal,
		offerAmount,
		discountAmount,
		netAmount,
		vatAmount,
		adjustmentAmount,
		grossAmount,
		cancelledAmount,
	} = calculateFile(file);

	return {
		$files: group.$files.concat($file),
		$isAllProductsWithVat: group.$isAllProductsWithVat && $isAllProductsWithVat,
		$isSomeProductsWithVat:
			group.$isSomeProductsWithVat || $isSomeProductsWithVat,
		products: group.products.concat(products),
		cancelledProducts: group.products.concat(cancelledProducts),
		subtotal: bigMath.add(group.subtotal, subtotal),
		discountAmount: bigMath.add(group.discountAmount, discountAmount),
		offerAmount: bigMath.add(group.offerAmount, offerAmount),
		netAmount: bigMath.add(group.netAmount, netAmount),
		vatAmount: bigMath.add(group.vatAmount, vatAmount),
		adjustmentAmount: bigMath.add(group.adjustmentAmount, adjustmentAmount),
		grossAmount: bigMath.add(group.grossAmount, grossAmount),
		cancelledAmount: bigMath.add(group.cancelledAmount, cancelledAmount),
	};
};

const baseFileGroupValue = {
	$files: [] as FileInputLike[],
	$isAllProductsWithVat: true,
	$isSomeProductsWithVat: false,
	products: [] as FileInputLike["products"][],
	cancelledProducts: [] as FileInputLike["cancelledProducts"][],
	subtotal: 0,
	discountAmount: 0,
	offerAmount: 0,
	netAmount: 0,
	vatAmount: 0,
	adjustmentAmount: 0,
	grossAmount: 0,
	cancelledAmount: 0,
};
