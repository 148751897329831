import Big from "big.js";

import { DISCOUNT_TYPE } from "~served/configs/enums";

import { DiscountInput } from "./types";

export const getDiscountAmount = (
	originalAmount: number,
	discountInput: DiscountInput,
) => {
	let discountAmount = 0;

	if (discountInput.type === DISCOUNT_TYPE.AMOUNT) {
		discountAmount = discountInput.value;
	}

	if (discountInput.type === DISCOUNT_TYPE.PERCENTAGE) {
		discountAmount = new Big(originalAmount)
			.mul(discountInput.value)
			.div(100)
			.round(2)
			.toNumber();
	}

	const discountedAmount = new Big(originalAmount)
		.minus(discountAmount)
		.round(2)
		.toNumber();

	return { discountAmount, discountedAmount };
};
