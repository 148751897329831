import Big, { BigSource } from "big.js";

import { UndefinedOrNull } from "~served/types/utils";

export const bigMath = {
	add: (base: BigSource = 0, ...values: (BigSource | undefined)[]) =>
		values
			.reduce<Big>(
				(prev, cur) => new Big(prev).add(cur ?? 0),
				new Big(base ?? 0),
			)
			.round(2)
			.toNumber(),
	sub: (base: BigSource = 0, ...values: (BigSource | undefined)[]) =>
		values
			.reduce<Big>(
				(prev, cur) => new Big(prev).sub(cur ?? 0),
				new Big(base ?? 0),
			)
			.round(2)
			.toNumber(),
	mul: (base: BigSource = 0, ...values: (BigSource | undefined)[]) =>
		values
			.reduce<Big>(
				(prev, cur) => new Big(prev).mul(cur ?? 0),
				new Big(base ?? 0),
			)
			.round(2)
			.toNumber(),
	div: (base: BigSource = 0, ...values: (BigSource | undefined)[]) =>
		values
			.reduce<Big>(
				// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- cannot divide by 0
				(prev, cur) => new Big(prev).div(cur || 1),
				new Big(base ?? 0),
			)
			.round(2)
			.toNumber(),
	mod: (base: BigSource = 0, ...values: (BigSource | undefined)[]) =>
		values
			.reduce<Big>(
				(prev, cur) => new Big(prev).mod(cur ?? 1),
				new Big(base ?? 0),
			)
			.round(2)
			.toNumber(),
};

export const isUndefinedOrNull = (
	val: any,
): val is UndefinedOrNull<typeof val> => {
	return val === undefined || val === null;
};
