import { useQuery } from "@tanstack/react-query";

import { apiGqlClient } from "~/libs/gql";
import { useSetCompanyId } from "~/store";
import { graphql } from "~/types/__generated/gql";
import { GetCurrentCompanyQuery } from "~/types/__generated/gql/graphql";

const query = /* GraphQL */ `
	query GetCurrentCompany {
		getCurrentCompany {
			_id
			createdAt
			updatedAt
			name
			address
			timezone
			logo
			vatTin
			customFields {
				customField
				textValue
				selectValues
				dateTimeValue
			}
			fileAlias {
				singular
				plural
			}
			paymentTypes {
				_id
				createdAt
				updatedAt
				name
			}
			vat
			isVatBuried
		}
	}
`;

export type GetCurrentCompanyResults =
	GetCurrentCompanyQuery["getCurrentCompany"];

export const getCurrentCompanyQueryKey = () => ["getCurrentCompany"];

export const useGetCurrentCompany = (enabled?: boolean) => {
	const setCompanyId = useSetCompanyId();
	return useQuery({
		enabled: enabled,
		queryKey: getCurrentCompanyQueryKey(),
		queryFn: async () =>
			apiGqlClient.request(graphql(query)).then((res) => res.getCurrentCompany),
		onSuccess: (data) => setCompanyId(data._id),
	});
};

export const useGetCurrentCompanyCache = () => {
	return useGetCurrentCompany(false);
};

export const getCurrentCompanyCache = () => {
	// eslint-disable-next-line @typescript-eslint/unbound-method
	return window.$queryClient?.getQueryData<GetCurrentCompanyResults>?.(
		getCurrentCompanyQueryKey(),
	);
};
